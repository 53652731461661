import getReducer, {
  IBaseState,
} from '../../sharedComponents/getNewDataCache';
import {
  apiBaseURL,
  fetchJSON,
  IYearlyDatum,
} from '../../Utils';
import {
  IWorkerRootState,
} from '../workerRootState';

interface IAPIResponse {
  data: IYearlyDatum[];
}

export type IState = IBaseState<IYearlyDatum[]>;

const FETCH_BEGIN = 'YEARLY_DATA_FETCH_BEGIN';
const FETCH_SUCCESS = 'YEARLY_DATA_FETCH_SUCCESS';
const FETCH_FAIL = 'YEARLY_DATA_FETCH_FAIL';
const FETCH_IF_NEEDED = 'YEARLY_DATA_FETCH_IF_NEEDED';

type IHash = {};

const {
  reducer,
  fetchIfNeeded,
  fetchDataEpic,
  getDataSelector,
} = getReducer<
  IYearlyDatum[],
  IWorkerRootState,
  typeof FETCH_IF_NEEDED,
  typeof FETCH_BEGIN,
  typeof FETCH_SUCCESS,
  typeof FETCH_FAIL,
  IHash,
  IAPIResponse
>({
  fetchIfNeededName: FETCH_IF_NEEDED,
  fetchBeginName: FETCH_BEGIN,
  fetchSuccessName: FETCH_SUCCESS,
  fetchFailName: FETCH_FAIL,
  getDataCache: (rootState: IWorkerRootState) => rootState.yearly,
  getFetchPromise: () => fetchJSON<IAPIResponse>(`${apiBaseURL}/data/year/?level=all`),
  hashFunction: () => '',
  getDataFromAPIResponse: ({data}) => data,
});

export default reducer;

export {fetchIfNeeded, fetchDataEpic, getDataSelector};
