// Reserved space on the left and bottom of chart for axes/ticks:
export const leftMargin = 90; // in pixels
export const bottomMargin = 55; // in pixels

interface ISize {
  width: number;
  height: number;
  top: number;
  left: number;
}
export const chartContainerMeasureToSVGMeasure = ({width, height, top, left}: ISize) => ({
  width: width - leftMargin,
  height: height - bottomMargin,
  top,
  left: left + leftMargin,
});
