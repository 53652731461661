import React from 'react';
import {
  Subscription,
} from 'rxjs';
import {
  Anchor,
  hideIsolateTooltipBackgroundColor,
  Root,
  TooltipContainer,
  TooltipControlItem,
  TooltipControlItemCircle,
  TooltipControlItemCircleSelected,
  TooltipControlItemText,
  TooltipControls,
  TooltipTitle,
} from '../newCategorySelector/ProductChoiceUtils';
import {
  IChoice,
} from './index';
import {
  getSingleAndDoubleClickStreams,
  SpotlightMode,
} from './Utils';

// Allow CSS custom properties
declare module 'csstype' {
  interface Properties {
    '--tooltip-border-top-color'?: string;
  }
}

interface IProps {
  choice: IChoice;
  deselectedCategories: number[];
  onShowHideClick: (value: number) => void;
  onIsolateClick: (value: number) => void;
  minWidthChoice?: number;
  isEnabled: boolean;
  spotlightMode: SpotlightMode;
}

interface IState {
  showTooltip: boolean;
}

export default class extends React.PureComponent<IProps, IState> {
  private iconEl: HTMLElement | null = null;
  private rememberIconElem = (el: HTMLElement | null) => this.iconEl = el;

  private iconDoubleClickSubscription: Subscription | undefined = undefined;
  private iconSingleClickSubscription: Subscription | undefined = undefined;

  constructor(props: IProps) {
    super(props);
    this.state = {
      showTooltip: false,
    };
  }

  componentDidMount() {
    const {iconEl} = this;
    if (iconEl !== null) {
      const {singleClick$, doubleClick$} = getSingleAndDoubleClickStreams(iconEl);
      this.iconDoubleClickSubscription = doubleClick$.subscribe(() => this.isolate());
      this.iconSingleClickSubscription = singleClick$.subscribe(() => this.showHide());
    }
  }

  componentWillUnmount() {
    const {iconDoubleClickSubscription, iconSingleClickSubscription} = this;
    if (iconDoubleClickSubscription !== undefined) {
      iconDoubleClickSubscription.unsubscribe();
    }
    if (iconSingleClickSubscription !== undefined) {
      iconSingleClickSubscription.unsubscribe();
    }
  }

  private showHide = () => {
    const {onShowHideClick, choice: {value}, isEnabled} = this.props;
    if (isEnabled === true) {
      onShowHideClick(value);
    }
  }
  private onShowHideClick = (e: React.MouseEvent<any>) => {
    e.preventDefault();
    e.stopPropagation();
    this.showHide();
  }
  private isolate = () => {
    const {onIsolateClick, choice: {value}, isEnabled} = this.props;
    if (isEnabled === true) {
      onIsolateClick(value);
    }
  }
  private onIsolateClick = (e: React.MouseEvent<any>) => {
    e.preventDefault();
    e.stopPropagation();
    this.isolate();
  }

  private onMouseEnter = () => this.setState(
    (prevState: IState): IState => ({...prevState, showTooltip: true}),
  )

  private onMouseLeave = () => this.setState(
    (prevState: IState): IState => ({...prevState, showTooltip: false}),
  )

  render() {
    const {
      choice: {getElement, label, value, color},
      deselectedCategories, spotlightMode,
      isEnabled,
      minWidthChoice,
    } = this.props;
    const {showTooltip} = this.state;

    let isShowOn: boolean;
    let isIsolateOn: boolean;
    if (spotlightMode === SpotlightMode.On) {
      isShowOn = false;
      isIsolateOn = !deselectedCategories.includes(value);
    } else {
      isIsolateOn = false;
      isShowOn = !deselectedCategories.includes(value);
    }

    const isIconSelected = !deselectedCategories.includes(value);

    const minWidth = (minWidthChoice === undefined) ? '' : `${minWidthChoice}px`;
    const rootStyle = {
      '--strike-through-opacity': (isEnabled ? 0 : 1),
    };

    const anchorStyle = {
      cursor: isEnabled ? 'pointer' : 'default',
      minWidth,
    };

    const ShowIconCircleComponent = !isShowOn && !isIsolateOn
      ? TooltipControlItemCircleSelected : TooltipControlItemCircle;
    const IsolateIconCircleComponent = isIsolateOn
      ? TooltipControlItemCircleSelected : TooltipControlItemCircle;

    let tooltip: React.ReactNode;
    if (showTooltip) {
      const tooltipStyle = {
        '--tooltip-text-color': 'white',
        '--tooltip-background-color': hideIsolateTooltipBackgroundColor,
        'height': '90px',
        'border-top': `10px solid ${color}`,
      };
      tooltip = (
        <TooltipContainer style={tooltipStyle}>
          <TooltipTitle>{label}</TooltipTitle>
          <TooltipControls>
            <TooltipControlItem onClick={this.onShowHideClick}>

              <ShowIconCircleComponent/>
              <TooltipControlItemText>
                Hide
              </TooltipControlItemText>
            </TooltipControlItem>

            <TooltipControlItem onClick={this.onIsolateClick}>

              <IsolateIconCircleComponent/>
              <TooltipControlItemText>
                Isolate
              </TooltipControlItemText>
            </TooltipControlItem>

          </TooltipControls>
        </TooltipContainer>
      );
    } else {
      tooltip = null;
    }

    return (
      <Root
        style={rootStyle}
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}
      >
        <Anchor
          style={anchorStyle}
          ref={this.rememberIconElem}
        >
          {getElement({isEnabled, isSelected: isIconSelected})}
        </Anchor>
        {tooltip}
      </Root>
    );
  }
}
