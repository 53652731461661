import React, {
  lazy,
  Suspense,
} from 'react';
import ReactDOM from 'react-dom';
import {
  connect,
  MapDispatchToProps,
  MapStateToProps,
} from 'react-redux';
import Select from 'react-select';
import {
  AnyAction,
  Dispatch,
} from 'redux';
import styled from 'styled-components';
import {
  postMessageToMergeWorker,
} from '../../getConfiguredStore';
import {
  ProductClass,
  QueryLevel,
  TradeFlow,
} from '../../graphQL/types/shared';
import {
  showDataNotes,
  showExports,
  showShare,
} from '../../overlay/actions';
import resizeMeasurementListener, {
  IListener,
} from '../../ResizeMeasurementListener';
import {
  IRootState,
} from '../../rootState';
import RegionSelector from '../../sharedComponents/categorySelector/RegionSelector';
// import RegionSelector from '../../sharedComponents/newCategorySelector/Region';

import {
  DataIssueType,
} from '../../sharedComponents/dataNotes/Utils';
import {
  getServiceCategoryNumber,
} from '../../sharedComponents/getIsService';
import ErrorOverlay from '../../sharedComponents/GraphError';
import Loading from '../../sharedComponents/GraphLoading';
import HighlightDropdown from '../../sharedComponents/HighlightDropdown';
import {
  getProductSection,
} from '../../sharedComponents/mainDropdown/Utils';
import Share, {
  DataNotesProps,
  TutorialModalProps,
} from '../../sharedComponents/newGraphShare';
import TriggerDataDownload from '../../sharedComponents/newGraphShare/TriggerDataDownload';
import RadioSelector from '../../sharedComponents/radioSelector';
import {
  IChoice,
} from '../../sharedComponents/radioSelector/Utils';
import YearSelector, {
  TimelineType,
} from '../../sharedComponents/timeline';
import {
  getDataSelector as getMainThreadCountryMetadataSelector,
} from '../../sharedData/newCountryMetadata';
import {
  getDataSelector as getMainThreadProductMetadataSelector,
} from '../../sharedData/newProductMetadata';
import {
  CountryMetadatumLevel as CountryLevel,
  DataSource,
  IDropdownOption,
  ILoadable,
  LoadableStatus,
  ProductMetadatumLevel as ProductLevel,
  Target,
} from '../../Utils';
import determineNewGraphStatus from '../../viz/determineNewGraphStatus';
import {
  RightLowerControlContainer,
} from '../../viz/ThreeColumnVizControlsGrid';
import {
  countryDetailLevelChoices,
  expandedProductDetailLevelChoices,
  GraphStatus as GraphStatusBase,
  GraphStatusCode,
  GraphSubject,
  IGraphDispatchProps,
  productClassChoices,
  productDetailLevelChoices,
  tradeFlowChoices,
  UpdateType,
  VizType,
} from '../../viz/Utils';
import {
  CategorySelectorContainer,
  ChartHeader,
  HighlightContainer,
  YearSelectorContainerWithoutPlayButton,
} from '../../viz/VizGrid';
import { vizSettingsPortalId } from '../../viz/VizSettings';
import {
  fetchIfNeeded as fetch_CCPY_CCIfNeeded,
} from '../../workerStore/fetchedData/countryCountryProductYearByCountryCountry';
import {
  fetchIfNeeded as fetch_CCPY_CPIfNeeded,
} from '../../workerStore/fetchedData/countryCountryProductYearByCountryProduct';
import {
  fetchIfNeeded as fetch_CCY_CIfNeeded,
} from '../../workerStore/fetchedData/countryCountryYearByCountry';
import {
  fetchIfNeeded as fetchCountryLevelMappingIfNeeded,
} from '../../workerStore/fetchedData/countryLevelMapping';
import {
  fetchIfNeeded as fetchCountryMetadataIfNeeded,
  IMetadatum as ICountryMetadatum,
} from '../../workerStore/fetchedData/countryMetadata';
import {
  fetchIfNeeded as fetch_CPY_CIfNeeded,
} from '../../workerStore/fetchedData/countryProductYearByCountry';
import {
  fetchIfNeeded as fetch_CPY_PIfNeeded,
} from '../../workerStore/fetchedData/countryProductYearByProduct';
import {
  fetchIfNeeded as fetch_CY_IfNeeded,
} from '../../workerStore/fetchedData/countryYear';
import {
  fetchIfNeeded as fetchProductLevelMappingIfNeeded,
  MappingType,
} from '../../workerStore/fetchedData/productLevelMapping';
import {
  fetchIfNeeded as fetchProductMetadataIfNeeded,
} from '../../workerStore/fetchedData/productMetadata';

/* Location aggregation groups-related fetches */
import {
  fetchIfNeeded as fetchGroupsMetadataIfNeeded,
  getDataSelector as getMainThreadGroupsMetadataSelector
} from '../../sharedData/groupsMetadata';
import {
  fetchIfNeeded as fetchGroupMembersMetadataIfNeeded,
  getDataSelector as getMainThreadGroupMembersMetadataSelector
} from '../../sharedData/groupMembersMetadata';

import {
  IMetadatum as IProductMetadatum,
} from '../../workerStore/fetchedData/productMetadata';
import {
  fetchIfNeeded as fetch_PY_IfNeeded,
} from '../../workerStore/fetchedData/productYear';
import {
  fetchIfNeeded as fetchYearlyDataIfNeded,
} from '../../workerStore/fetchedData/yearlyData';
import {
  RoutingInputCheckResult,
} from '../../workerStore/newGraphDataCache';
import {
  checkForInvalidRoutingInput,
  getHashInputFromRoutingAndUIState,
  IHasError,
  IInputFromURLRouting,
  IUIState,
  MergedData,
} from '../../workerStore/stack/getReducer';
import {
  ErrorCode,
  IDiscriminant,
  IMergeOutput,
  IYearDataPoint,
  Layout,
  Ordering,
  PopulationAdjustment,
} from '../../workerStore/stack/Utils';
import Chart from '../chart';
import {
  IndividualAdjustments,
} from '../chart/YAxisLabel';
import {
  NonInteractiveChartContainer,
  StackChartContainer,
} from '../Grid';
import {
  getMergedData,
  getUIState,
  getUpdateType,
  reset,
  startSubscribing,
  stopSubscribing,
  updateInputFromURLRouting,
  updateUIState,
} from '../reducer';
import {
  chartContainerMeasureToSVGMeasure,
} from '../Utils';
import AxisProjections from './axisProjections';
import DetailOverlayContainer from './DetailOverlayContainer';
import StackGraphTitle from './StackGraphTitle';
import Tooltips from './TooltipsContainer';
import { determineDataFacet } from '../../graphQL/Utils';
import { NewGraphTitle } from '../../viz/NewChartTitle';

const ProductCategorySelector = lazy(() => import(
  /* tslint:disable-next-line:trailing-comma*/
  /* webpackChunkName: "productClassSelector" */ '../../sharedComponents/categorySelector/ProductCategorySelector'
));

const Dropdown = styled(Select)`
  width: 100%;
`;

const layoutChoices: Array<IChoice<Layout>> = [
  {
    value: Layout.Value,
    label: __lexiconText('layoutSelector.value'),
  },
  {
    value: Layout.Share,
    label: __lexiconText('layoutSelector.share'),
  },
];

const orderingChoices: Array<IChoice<Ordering>> = [
  {
    value: Ordering.Community,
    label: __lexiconText('orderingSelector.community'),
  },
  {
    value: Ordering.Total,
    label: __lexiconText('orderingSelector.totals'),
  },
];

// Attempt to preserve object reference for tooltip info if the new tooltip info
// holds the same information as the old one. This is needed to avoid triggering
// unnecessary reconcilation for the chart element which is a `PureComponent`:
const getNewTooltipInfo =
  (prevInfo: IYearDataPoint | undefined, nextInfo: IYearDataPoint): IYearDataPoint | undefined => {

  let result: IYearDataPoint | undefined;
  if (nextInfo === undefined || prevInfo === undefined) {
    result = nextInfo;
  } else {
    if (prevInfo.ribbonId === nextInfo.ribbonId && prevInfo.year === nextInfo.year) {
      result = prevInfo;
    } else {
      result = nextInfo;
    }
  }
  return result;
};

// Remove the "Mid" detail level:
const getNextGraphStatus = (
    nextComputedData: MergedData, prevGraphStatus: GraphStatus): GraphStatus => {

  let preliminaryGraphStatus: GraphStatus;
  if (nextComputedData.hasError === true) {
    preliminaryGraphStatus = {
      status: GraphStatusCode.Fail,
      value: nextComputedData.errorCode,
      loadableStatus: LoadableStatus.NotPresent,
    };
  } else {

    preliminaryGraphStatus = determineNewGraphStatus<IMergeOutput, ErrorCode>(
      ErrorCode.NoData, prevGraphStatus, nextComputedData.value,
    );
  }

  // If there's only data for a single year (or no year) across the entire graph or there's no ribbon
  // to show (possibly because of cateogry filtering) then show "no data" message:
  let finalGraphStatus: GraphStatus;
  if (preliminaryGraphStatus.status === GraphStatusCode.Success) {
    const {value: {ribbons, yearsWithData}} = preliminaryGraphStatus;
    const hasNoValidData = (ribbons.length === 0) || (yearsWithData.length <= 1);
    finalGraphStatus = hasNoValidData ? {
      status: GraphStatusCode.Fail,
      value: ErrorCode.NoData,
      loadableStatus: LoadableStatus.Present,
    } : preliminaryGraphStatus;
  } else {
    finalGraphStatus = preliminaryGraphStatus;
  }
  return finalGraphStatus;
};

const determineUIUpdateType = (props: IProps) => {
  const {productLevel, countryLevel, routingInputValidity} = props;
  if (routingInputValidity.isValid === true) {
    let updateType: UpdateType;
    const {extraInfo} = routingInputValidity;
    if (extraInfo.subject === GraphSubject.Country && countryLevel === CountryLevel.country) {
      updateType = UpdateType.Hard;
    } else if (extraInfo.subject === GraphSubject.Product && productLevel === ProductLevel.fourDigit) {
      updateType = UpdateType.Hard;
    } else {
      updateType = UpdateType.Soft;
    }
    return updateType;
  } else {
    let updateType: UpdateType;
    updateType = UpdateType.Soft;
    return updateType;
    // Throwing an error here has unintended consequences.
    // Disabling this error and manually returning a Soft update;
    // need to investigate why determineUIUpdateType is getting called
    // in situations where routingInputValidity.isValud === false
    // throw new Error('Should not be called when routing input is not valid');
  }
};

const resetCertainStatesWhenChangingUIControl =
  (nextProps: IProps) =>
    (prevState: IState): IState => {

  let highlighted: number | undefined;
  let selected: IYearDataPoint | undefined;
  let hovered: IYearDataPoint | undefined;

  if (nextProps.routingInputValidity.isValid === true &&
      prevState.mirrored.routingInputValidity.isValid === true) {

    const needToResetMouseInteraction =
      (nextProps.productClass !== prevState.mirrored.productClass) ||
      (nextProps.queryLevel !== prevState.mirrored.queryLevel) ||
      (nextProps.country !== prevState.mirrored.country) ||
      (nextProps.product !== prevState.mirrored.product) ||
      (nextProps.partner !== prevState.mirrored.partner) ||
      (nextProps.target !== prevState.mirrored.target) ||
      (nextProps.productLevel !== prevState.mirrored.productLevel) ||
      (nextProps.countryLevel !== prevState.mirrored.countryLevel) ||
      (nextProps.computedData.hasError !== prevState.mirrored.computedData.hasError);

    if (needToResetMouseInteraction === true) {
      highlighted = undefined;
      selected = undefined;
      hovered = undefined;
    } else {
      highlighted = prevState.highlighted;
      selected = prevState.selected;
      hovered = prevState.hovered;
    }

  } else {
    highlighted = undefined;
    selected = undefined;
    hovered = undefined;
  }

  return {
    ...prevState,
    highlighted, selected, hovered,
  };

};
const getStateUpdater = (
    // Note: `unlaggedStateTransformer` is the state transformer function
    // usually passed into `setState` where we perform any change in "unlagged
    // state" here e.g. set IDs of highlighted products or products with
    // detailed overlay:
    unlaggedStateTransformer: (prevState: IUnlaggedState) => IUnlaggedState,
    // Default to `this.props` because most of the time, `updateState` is used
    // in place of `setState` and as such, `nextProps` haven't changed.
    nextProps: IProps,
  ) =>
    (prevState: IState): IState => {

  const newUnlaggedState = unlaggedStateTransformer(prevState);
  const graphStatus = getNextGraphStatus(nextProps.computedData, prevState.graphStatus);
  const newState = {...newUnlaggedState, graphStatus};
  return newState as IState;
};

export interface IOwnProps extends IInputFromURLRouting {
  onEndYearChange: (year: number) => void;
  onStartYearChange: (year: number) => void;
  onTradeFlowChange: (tradeFlow: TradeFlow) => void;
  onProductClassChange: (productClass: ProductClass) => void;
}

interface IStateProps extends IUIState {
  // Computed graph data:
  computedData: MergedData;
  updateType: UpdateType;
  routingInputValidity: RoutingInputCheckResult<IHasError, IDiscriminant>;
  countryMetadata: ILoadable<Map<number, ICountryMetadatum>>;
  productMetadata: ILoadable<Map<number, IProductMetadatum>>;
  announcementBannerShown: boolean;
}

type IProps = IOwnProps & IStateProps & IGraphDispatchProps & TutorialModalProps;

interface IUnlaggedState {
  highlighted: number | undefined;

  detailed: IYearDataPoint | undefined;

  hovered: IYearDataPoint | undefined;
  selected: IYearDataPoint | undefined;

  // State mirrored from props:
  mirrored: {
    country: IProps['country'];
    queryLevel: IProps['queryLevel'];
    product: IProps['product'];
    startYear: IProps['startYear'];
    endYear: IProps['endYear'];
    productClass: IProps['productClass'];
    tradeDirection: IProps['tradeDirection'];
    tradeFlow: IProps['tradeFlow'];
    partner: IProps['partner'];
    target: IProps['target'];
    computedData: IProps['computedData'];
    routingInputValidity: IProps['routingInputValidity'];
    productLevel: IProps['productLevel'];
    countryLevel: IProps['countryLevel'];
  };
}

export type GraphStatus = GraphStatusBase<IMergeOutput, ErrorCode>;

interface IState extends IUnlaggedState {
  graphStatus: GraphStatus;
  showDataDownload: boolean;
  graphTitle: string;
}

class Stack extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    const unlaggedInitialState: IUnlaggedState = {
      detailed: undefined,
      highlighted: undefined,
      hovered: undefined,
      selected: undefined,
      mirrored: {
        country: props.country,
        queryLevel: props.queryLevel,
        product: props.product,
        startYear: props.startYear,
        endYear: props.endYear,
        productClass: props.productClass,
        tradeDirection: props.tradeDirection,
        tradeFlow: props.tradeFlow,
        partner: props.partner,
        target: props.target,
        computedData: props.computedData,
        routingInputValidity: props.routingInputValidity,
        productLevel: props.productLevel,
        countryLevel: props.countryLevel,
      },
    };

    const graphStatus = getNextGraphStatus(props.computedData, {
      status: GraphStatusCode.Initial,
      loadableStatus: LoadableStatus.Initial,
    });


    this.state = {
      ...unlaggedInitialState,
      graphStatus,
      showDataDownload: false,
      graphTitle: '',
    };

  }
  /* Start of setState-related methods */
  setState() {
    throw new Error('Do not call `setState` directly in this component. Use `updateState` instead.');
  }

  private updateState(
      // Note: `unlaggedStateTransformer` is the state transformer function
      // usually passed into `setState` where we perform any change in "unlagged
      // state" here e.g. set IDs of highlighted products or products with
      // detailed overlay:
      unlaggedStateTransformer: (prevState: IUnlaggedState) => IUnlaggedState,
      // Default to `this.props` because most of the time, `updateState` is used
      // in place of `setState` and as such, `nextProps` haven't changed.
      nextProps: IProps = this.props): void {


    // Note: have to call `setState` on `super` because we disable `setState` on
    // this instance;
    super.setState(getStateUpdater(unlaggedStateTransformer, nextProps));
  }
  /* End of setState-related methods */

  /* Start of data fetching methods */
  private fetchProductRelatedBackgroundData({productClass, dispatchToWorkerStore}: IProps) {
    dispatchToWorkerStore(fetchProductLevelMappingIfNeeded({productClass, mappingType: MappingType.Hi2Low}));
    dispatchToWorkerStore(fetchProductLevelMappingIfNeeded({productClass, mappingType: MappingType.Hi2Mid}));
    dispatchToWorkerStore(fetchProductMetadataIfNeeded({productClass}));
  }

  private fetchCountryRelatedBackgroundData({dispatchToWorkerStore, dispatchToBothStores}: IProps) {
    dispatchToBothStores(fetchCountryMetadataIfNeeded({}));
    dispatchToWorkerStore(fetchCountryLevelMappingIfNeeded({}));

  }
  private fetchData(props: IProps) {
    const {dispatchToWorkerStore, dispatchToBothStores, productClass, product, country, countryLevel, queryLevel, target, partner, productLevel} = props;

    dispatchToWorkerStore(fetch_PY_IfNeeded({productClass}));
    dispatchToWorkerStore(fetch_CY_IfNeeded({}));
    // Adding location aggregation groups-related fetches
    dispatchToBothStores(fetchGroupsMetadataIfNeeded({}));
    dispatchToBothStores(fetchGroupMembersMetadataIfNeeded({}));

    if (target === Target.Product) {
      if (country !== undefined && product === undefined) {
        this.fetchProductRelatedBackgroundData(props);
        dispatchToWorkerStore(fetch_CPY_CIfNeeded({country, queryLevel, productClass, level: productLevel}));
      } else if (country === undefined && product !== undefined) {
        this.fetchCountryRelatedBackgroundData(props);
        dispatchToWorkerStore(fetch_CPY_PIfNeeded({productClass, product, countryLevel}));
      } else if (country !== undefined && product !== undefined) {
        this.fetchCountryRelatedBackgroundData(props);
        dispatchToWorkerStore(fetch_CCPY_CPIfNeeded({productClass, product, country, countryLevel, queryLevel}));
      }
    } else {
      if (country !== undefined && partner === undefined) {
        this.fetchCountryRelatedBackgroundData(props);
        dispatchToWorkerStore(fetch_CCY_CIfNeeded({country, queryLevel}));
        dispatchToWorkerStore(fetchYearlyDataIfNeded({}));
      } else if (country !== undefined && partner !== undefined) {
        this.fetchProductRelatedBackgroundData(props);
        dispatchToWorkerStore(fetch_CCPY_CCIfNeeded({
          productClass, country, queryLevel, partner, level: productLevel,
        }));
      }
    }
  }
  /* End of data fetching methods */

  /* Start of lifecycle methods */
  componentDidMount() {
    // Register "resizer" method:
    resizeMeasurementListener.addListener(this.resizeListener);

    const {
      dispatchToWorkerStore,
      productClass, startYear, endYear, country, queryLevel,
      tradeDirection, tradeFlow, product, target, partner,
      dispatchToBothStores,
    } = this.props;
    dispatchToWorkerStore(startSubscribing());
    dispatchToBothStores(updateInputFromURLRouting({
      productClass, startYear, endYear, country, queryLevel,
      tradeDirection, tradeFlow, product, target, partner,
    }, UpdateType.Hard));
    this.fetchData(this.props);
  }

  componentWillUnmount() {
    const {dispatchToBothStores, dispatchToWorkerStore} = this.props;
    // Clean up the UI state and derived data in the redux store:
    // and stop computing derived data:
    dispatchToBothStores(reset());
    dispatchToWorkerStore(stopSubscribing());
    // Unregister "resizer" method:
    resizeMeasurementListener.removeListener(this.resizeListener);
  }

  static getDerivedStateFromProps(nextProps: IProps, prevState: IState): IState {
    const mirrored = {
      country: nextProps.country,
      queryLevel: nextProps.queryLevel,
      product: nextProps.product,
      startYear: nextProps.startYear,
      endYear: nextProps.endYear,
      productClass: nextProps.productClass,
      tradeDirection: nextProps.tradeDirection,
      tradeFlow: nextProps.tradeFlow,
      partner: nextProps.partner,
      target: nextProps.target,
      computedData: nextProps.computedData,
      routingInputValidity: nextProps.routingInputValidity,
      productLevel: nextProps.productLevel,
      countryLevel: nextProps.countryLevel,
    };

    // Reset certain local UI state when routing/redux-store UI state changes:
    const updatedState = getStateUpdater(
      resetCertainStatesWhenChangingUIControl(nextProps),
      nextProps,
    )(prevState);

    return {
      ...updatedState,
      mirrored,
    };
  }

  componentDidUpdate(prevProps: IProps, prevState: IState) {
    const nextProps = this.props;
    if (nextProps !== prevState.mirrored) {
      if (nextProps.country !== prevState.mirrored.country ||
          nextProps.queryLevel !== prevState.mirrored.queryLevel ||
          nextProps.product !== prevState.mirrored.product ||
          nextProps.startYear !== prevState.mirrored.startYear ||
          nextProps.endYear !== prevState.mirrored.endYear ||
          nextProps.productClass !== prevState.mirrored.productClass ||
          nextProps.tradeDirection !== prevState.mirrored.tradeDirection ||
          nextProps.tradeFlow !== prevState.mirrored.tradeFlow ||
          nextProps.partner !== prevState.mirrored.partner ||
          nextProps.target !== prevState.mirrored.target ||
          nextProps.productLevel !== prevState.mirrored.productLevel) {

        this.fetchData(nextProps);
      }

      // Trigger computation when props from routing changes:
      const {
        country, queryLevel, endYear, startYear, productClass, tradeDirection, tradeFlow,
        product, target, partner, populationAdjustment,
        routingInputValidity,
        dispatchToBothStores,
      } = nextProps;


      // If the new graph shows countries but a popoulation adjustment is set,
      // remove the population adjustment because population adjustment is not
      // available for graphs about countries:

      // TODO: find a better way to handle situation where the UI state (in this
      // case the inflation/population adjustment) depends on the URL routing
      // params:
      if (routingInputValidity.isValid === true &&
            routingInputValidity.extraInfo.subject === GraphSubject.Country) {
        if (populationAdjustment !== PopulationAdjustment.NotAdjusted) {
          dispatchToBothStores(updateUIState({
            populationAdjustment: PopulationAdjustment.NotAdjusted,
          }, UpdateType.Hard));
        }
      }

      if (nextProps.country !== prevState.mirrored.country ||
          nextProps.queryLevel !== prevState.mirrored.queryLevel ||
          nextProps.productClass !== prevState.mirrored.productClass ||
          nextProps.product !== prevState.mirrored.product ||
          nextProps.partner !== prevState.mirrored.partner ||
          nextProps.target !== prevState.mirrored.target ||
          (nextProps.productLevel !== prevState.mirrored.productLevel &&
            nextProps.productLevel === ProductLevel.sixDigit)) {

        dispatchToBothStores(updateInputFromURLRouting({
          productClass, startYear, endYear, country, queryLevel, 
          tradeDirection, tradeFlow, product, target, partner,
        }, UpdateType.Hard));

      } else if (nextProps.endYear !== prevState.mirrored.endYear ||
                  nextProps.startYear !== prevState.mirrored.startYear ||
                  nextProps.tradeDirection !== prevState.mirrored.tradeDirection ||
                  nextProps.tradeFlow !== prevState.mirrored.tradeFlow) {

        dispatchToBothStores(updateInputFromURLRouting({
          productClass, startYear, endYear, country, queryLevel,
          tradeDirection, tradeFlow, product, target, partner,
        }, determineUIUpdateType(nextProps)));
      }

      if (nextProps.announcementBannerShown !== prevProps.announcementBannerShown) {
        this.measureDOMLayout();
      }
    }
  }
  /* End of lifecycle methods */

  /* Start of size measurement-related methods */
  private detailOverlayEl: HTMLElement | null = null;
  private rememberDetailOverlayEl = (el: HTMLElement | null) => this.detailOverlayEl = el;

  private chartContainerEl: HTMLElement | null = null;
  private rememberChartContainer = (el: HTMLElement | null) => {
    this.chartContainerEl = el;
    if (el !== null) {
      this.measureDOMLayout();
      // Listen to click events so that we can close the detailed tooltip when
      // user clicks outside the chart area:
      window.addEventListener('click', this.detectClickOutside);
    } else {
      window.removeEventListener('click', this.detectClickOutside);
    }
  }

  // Detect if click is outside the chart area. If so, close the detail tooltip.
  // However, don't close the tooltip if the area the user clicks on is the
  // detail overlay because it's inconvenient:
  private detectClickOutside = (event: MouseEvent) => {
    if (this.chartContainerEl !== null &&
        !this.chartContainerEl.contains(event.target as Node)) {

      if (this.detailOverlayEl !== null &&
          this.detailOverlayEl !== undefined &&
          this.detailOverlayEl.contains(event.target as Node)) {

        return;
      } else {
        this.resetSelected();
      }
    }
  }

  private chartRootEl: HTMLElement | null = null;
  private rememberChartRootEl = (el: HTMLElement | null) => this.chartRootEl = el;

  private measureDOMLayout() {
    if (this.chartContainerEl !== null) {
      const {
        width: decimalWidth,
        height: decimalHeight,
        top: decimalTop,
        left: decimalLeft,
      } = this.chartContainerEl.getBoundingClientRect();

      const {top, left, width, height} = chartContainerMeasureToSVGMeasure({
        width: Math.floor(decimalWidth),
        height: Math.floor(decimalHeight),
        top: Math.floor(decimalTop),
        left: Math.floor(decimalLeft),
      });

      this.props.dispatchToBothStores(updateUIState({
        svgHeight: height,
        svgWidth: width,
        svgTop: top,
        svgLeft: left,
      }, UpdateType.Hard));
    }
  }
  private resizeListener: IListener = this.getResizeListener();
  private getResizeListener(): IListener {
    let decimalWidth: number;
    let decimalHeight: number;
    let decimalTop: number;
    let decimalLeft: number;
    let originalPositioning: string;

    // Element that we need to remove from regular DOM layout flow so as to allow the
    // chart container to "relax" to its "natural" width:
    let target: HTMLElement | null;

    const before = () => {
      target = this.chartRootEl;
      if (target !== null) {
        // Remember the original value of `position` and `height` so that we can restore them later:
        const computedStyle = window.getComputedStyle(target);
        originalPositioning = computedStyle.position!;
        if (originalPositioning !== null) {
          // ... then take the graph's root elemen out of the flow by setting its `position`
          // to `absolute`. This allows `this.chartContainerEl` to resize to is "natural"
          // width...
          target.style.position = 'absolute';
        }
      }
    };

    const measure = () => {
      const chartContainerEl = this.chartContainerEl;
      if (chartContainerEl !== null) {
        // ... which we measure (excluding the height)...
        const {width, top, left, height} = chartContainerEl.getBoundingClientRect();
        decimalWidth = width;
        decimalTop = top;
        decimalLeft = left;
        decimalHeight = height;
      }
    };

    const after = () => {
      target = this.chartRootEl;
      if (target !== null) {
        // ... then restore the `position` and `height` to their original values:
        target.style.position = originalPositioning;
      }

      const {top, left, width, height} = chartContainerMeasureToSVGMeasure({
        top: Math.floor(decimalTop),
        left: Math.floor(decimalLeft),
        width: Math.floor(decimalWidth),
        height: Math.floor(decimalHeight),
      });
      this.props.dispatchToBothStores(updateUIState({
        svgWidth: width,
        svgHeight: height,
        svgTop: top,
        svgLeft: left,
      }, UpdateType.Hard));
    };

    return {
      before,
      measure,
      after,
    };
  }
  /* End of size measurement-related methods */

  /* Start of UI state update methods */
  private onAdjustmentChange = ({populationAdjustment, inflationAdjustment}: IndividualAdjustments) =>
      this.props.dispatchToBothStores(
        updateUIState({inflationAdjustment, populationAdjustment}, determineUIUpdateType(this.props)),
      )
  private changeProductLevel = (productLevel: ProductLevel) => {
    const updateType = (productLevel === ProductLevel.fourDigit) ? UpdateType.Hard : UpdateType.Soft;
    this.props.dispatchToBothStores(updateUIState({productLevel}, updateType));
  }

  private changeCountryLevel = (countryLevel: CountryLevel) => {
    const updateType = (countryLevel === CountryLevel.country) ? UpdateType.Hard : UpdateType.Soft;
    this.props.dispatchToBothStores(updateUIState({countryLevel}, updateType));
  }

  private onLayoutChange = (layout: Layout) => this.props.dispatchToBothStores(
    updateUIState({layout}, determineUIUpdateType(this.props)),
  )

  private onOrderingChange = (ordering: Ordering) =>
    this.props.dispatchToBothStores(updateUIState({ordering}, determineUIUpdateType(this.props)))

  private updateProductHighlight = (selection: IDropdownOption | null) => {
    if (selection === null) {
      this.updateState(
        (prevState: IState): IState => ({...prevState, highlighted: undefined}),
      );
    } else {
      const {value} = selection;
      this.updateState(
        (state: IState): IState => ({...state, highlighted: value}),
      );
    }
  }

  private showTooltip = (nextHoverInfo: IYearDataPoint) => this.updateState(
    (prevState: IUnlaggedState): IUnlaggedState => ({
      ...prevState, hovered: getNewTooltipInfo(prevState.hovered, nextHoverInfo),
    }),
  )

  private hideTooltip = () => this.updateState(
    (prevState: IUnlaggedState): IUnlaggedState => ({...prevState, hovered: undefined}),
  )

  private setSelection = (nextTooltipInfo: IYearDataPoint) => this.updateState(
    (prevState: IUnlaggedState): IUnlaggedState => ({
      ...prevState,
      selected: getNewTooltipInfo(prevState.selected, nextTooltipInfo),
      detailed: getNewTooltipInfo(prevState.detailed, nextTooltipInfo),
    }),
  )

  private resetDetailed = () => this.updateState(
    (prevState: IUnlaggedState): IUnlaggedState => ({...prevState, detailed: undefined}),
  )

  private resetSelected = () => this.updateState(
    (prevState: IUnlaggedState): IUnlaggedState => ({...prevState, selected: undefined}),
  )

  private setDeselectedCategories = (deselectedCategories: number[]) => this.props.dispatchToBothStores(
    updateUIState({deselectedCategories}, determineUIUpdateType(this.props)),
  )

  private resetDeselectedCategories = () => this.props.dispatchToBothStores(
    updateUIState({deselectedCategories: []}, determineUIUpdateType(this.props)),
  )
  /* End of UI state update methods */

  //#region Exports-related methods
  private onShareClick = () => this.props.dispatchToMainStore(showShare(VizType.Stack));
  private onExportsClick = () => this.props.dispatchToMainStore(showExports(VizType.Stack, this.state.graphTitle));
  private onDataDownloadClick = () => {
    this.updateState(
      (state: IState): IState => ({...state, showDataDownload: true}),
    );
  }
  private dismissDataDownload = () => {
    this.updateState(
      (state: IState): IState => ({...state, showDataDownload: false}),
    );
  }
  //#endregion

  private updateGraphTitle = (val: string) => {
   
    this.updateState(prevState => ({...prevState, graphTitle: val}));
  }
  

  render() {
    const props = this.props;
    const {
      svgWidth, svgHeight, svgTop, svgLeft,
      updateType,
      onProductClassChange, onTradeFlowChange,
      layout, ordering,
      inflationAdjustment, populationAdjustment, deselectedCategories,
      onEndYearChange, onStartYearChange, countryMetadata,
      dispatchToMainStore, productMetadata,
      product, groupsMetadata, groupMembersMetadata,
      setDisplayIntraRegionTradeDisclaimer
    } = props;
    const {
      graphStatus, highlighted, hovered, selected, detailed, graphTitle,
      mirrored: {
        startYear, endYear,
        productClass, tradeDirection, tradeFlow,
        routingInputValidity,
        productLevel, countryLevel, country, queryLevel, partner, target
      },
    } = this.state;


    /* Start of intra-region trade disclaimer */
    if(groupsMetadata.data && country && partner && target === Target.Partner) {

      let primaryQueryLevel = queryLevel;
      let primaryLocationId = `${queryLevel}-${country}`;
      let targetQueryLevel = partner.includes("group") ? QueryLevel.Group : QueryLevel.Location;
      let targetLocationNumericId = Number(partner.replace("group-","").replace("location-",""));

      // Determine if target is parent or child of primary location -- procedure for subregions
      const subregionParentOrChildRelationship = [...groupsMetadata.data.entries()].filter(elem => {
        let groupInfo = elem[1];
        return groupInfo.group_type === "subregion" && groupInfo.parent_type === "region" && (groupInfo.group_id == country && groupInfo.parent_id == targetLocationNumericId) || (groupInfo.group_id == targetLocationNumericId && groupInfo.parent_id == country);
      });
  

      // If primary and target location are identical
      if(primaryLocationId === partner) {
        setDisplayIntraRegionTradeDisclaimer(true);
      } else if(subregionParentOrChildRelationship.length != 0) {
        setDisplayIntraRegionTradeDisclaimer(true);
      } else {
        setDisplayIntraRegionTradeDisclaimer(false);
      }
    } else {
      setDisplayIntraRegionTradeDisclaimer(false);
    }
    /* End of intra-region trade disclaimer */

    /* Start of loading spinner */
    let loadingSpinner: JSX.Element | null;
    if (graphStatus.status === GraphStatusCode.Initial ||
        graphStatus.status === GraphStatusCode.LoadingAfterFailure ||
        graphStatus.status === GraphStatusCode.LoadingAfterSuccess) {

      if (updateType === UpdateType.Hard) {
        loadingSpinner = <Loading/>;
      } else {
        loadingSpinner = null;
      }
    } else {
      loadingSpinner = null;
    }
    /* End of loading spinner */

    /* Start of error message */
    let errorOverlay: JSX.Element | null;
    if (graphStatus.status === GraphStatusCode.Fail) {
      const {value} = graphStatus;
      let message: string;
      if (value === ErrorCode.NoData) {
        message = __lexiconText('error.noData');
      } else if (value === ErrorCode.PickTypeOfAggregationEntity) {
        message = __lexiconText('error.productMode');
      } else {
        message = __lexiconText('error.partnerMode');
      }
      errorOverlay = (
        <ErrorOverlay message={message}/>
      );
    } else {
      errorOverlay = null;
    }
    /* End of error message */

    let isDataNotesWarningActive: boolean, isServicesEnabled: boolean;
    let isServicesNotAvailableForAllYears: boolean;
    const dataNotes: DataIssueType[] = [];
    if (countryMetadata.status !== LoadableStatus.Present ||
        productMetadata.status !== LoadableStatus.Present || isNaN(country) || queryLevel !== QueryLevel.Location) {
      isDataNotesWarningActive = false;
      isServicesEnabled = true; // not applicable
      isServicesNotAvailableForAllYears = false; // not applicable
    } else {
      let showCountryNotes: boolean;
      if (country !== undefined) {
        const retrievedMetadatum = countryMetadata.data.get(country);
        if (retrievedMetadatum === undefined) {
          throw new Error('Cannot find metadatum for country' + country);
        }
        showCountryNotes = !retrievedMetadatum.is_trusted;
      } else {
        showCountryNotes = false;
      }

      let showServicesNotes: boolean;
      if (routingInputValidity.isValid === true) {
        const {extraInfo} = routingInputValidity;
        if (extraInfo.source === DataSource.CCY) {
          // If "partnert" tab is selected without selecting any country:
          showServicesNotes = true;
          isServicesEnabled = true; // not applicable
          isServicesNotAvailableForAllYears = false; // not applicable
        } else if (extraInfo.source === DataSource.CCPY &&
                  extraInfo.subject === GraphSubject.Product) {
          // If "partnert" tab is selected and a country is selected:
          showServicesNotes = true;
          isServicesEnabled = false;
          isServicesNotAvailableForAllYears = false;
        } else if (extraInfo.source === DataSource.CPY &&
                    extraInfo.subject === GraphSubject.Country) {
          // Show services note if user selects CPY-P and picks a product
          // within the services section:
          if (product === undefined) {
            showServicesNotes = false;
          } else {
            try {
              const section = getProductSection(product, productMetadata.data);
              showServicesNotes = (section === getServiceCategoryNumber(productClass));
            } catch (e) {
              showServicesNotes = false;
            }
          }
          isServicesEnabled = true; // not applicable
          isServicesNotAvailableForAllYears = false; // not applicable
        } else if (extraInfo.source === DataSource.CPY &&
                    extraInfo.subject === GraphSubject.Product) {
          // In CPY-C, if country is known to not provide reliable services
          // data, then show data warning:
          if (country !== undefined && !isNaN(country)) {
            const retrievedMetadatum = countryMetadata.data.get(country);
            if (retrievedMetadatum === undefined) {
              throw new Error('Cannot find metadatum for country' + country);
            }
            if (productClass === ProductClass.HS && !retrievedMetadatum.reported_serv_recent) {
              isServicesNotAvailableForAllYears = true;
              isServicesEnabled = true;
              showServicesNotes = true;
            } else if (productClass === ProductClass.SITC && startYear < 1980 && endYear > 1980) {
              isServicesNotAvailableForAllYears = true;
              isServicesEnabled = true;
              showServicesNotes = true;
            } else if (productClass === ProductClass.SITC && endYear < 1980) {
              isServicesNotAvailableForAllYears = false;
              isServicesEnabled = false;
              showServicesNotes = true;
            } else {
              showServicesNotes = false;
              isServicesEnabled = true;
              isServicesNotAvailableForAllYears = false;
            }
          } else {
            showServicesNotes = false;
            isServicesEnabled = true;
            isServicesNotAvailableForAllYears = false;
          }
        } else {
          showServicesNotes = false;
          isServicesEnabled = true; // not applicable
          isServicesNotAvailableForAllYears = false; // not applicable
        }
      } else {
        showServicesNotes = false;
        isServicesEnabled = true; // not applicable
        isServicesNotAvailableForAllYears = false; // not applicable
      }

      if (showCountryNotes === true || showServicesNotes === true) {
        if (showCountryNotes) {
          dataNotes.push(DataIssueType.UnreliableCountry);
        }
        if (showServicesNotes) {
          dataNotes.push(DataIssueType.ServicesInGeneral);
        }
        isDataNotesWarningActive = true;
      } else {
        isDataNotesWarningActive = false;
      }
    }
    const dataNotesProps: DataNotesProps = {
      isDataNotesWarningActive,
      launchDataNotes: () => dispatchToMainStore(showDataNotes(dataNotes)),
    };


    /* Start of actual chart */
    let stackChartElem: JSX.Element | null;
    let share: JSX.Element | null;
    let dataDownload: React.ReactElement<any> | null;
    if (graphStatus.status === GraphStatusCode.Success ||
        graphStatus.status === GraphStatusCode.LoadingAfterSuccess) {

      const {
        value: {ribbons, xAxisMin, xAxisMax, yAxisMin, yAxisMax },
      } = graphStatus;



      const chartType = (routingInputValidity.isValid === true) ? routingInputValidity.extraInfo : undefined;
      stackChartElem = (
        <Chart
          svgWidth={svgWidth} svgHeight={svgHeight}
          svgTop={svgTop} svgLeft={svgLeft}
          ribbons={ribbons} selected={selected}
          highlighted={highlighted}
          showTooltip={this.showTooltip}
          hideTooltip={this.hideTooltip}
          onRibbonClick={this.setSelection}
          rememberChartRootEl={this.rememberChartRootEl}
          xAxisMin={xAxisMin} xAxisMax={xAxisMax}
          yAxisMin={yAxisMin} yAxisMax={yAxisMax}
          layout={layout} tradeDirection={tradeDirection}
          tradeFlow={tradeFlow}
          inflationAdjustment={inflationAdjustment} populationAdjustment={populationAdjustment}
          type={chartType}
          onYAxisChange={this.onAdjustmentChange}
        />
      );
      share = (
        <Share
          launchShare={this.onShareClick}
          launchExports={this.onExportsClick}
          showDataDownload={this.onDataDownloadClick}
          dataNotesProps={dataNotesProps}
          isTutorialModalOpen={this.props.isTutorialModalOpen}
          setIsTutorialModalOpen={this.props.setIsTutorialModalOpen}
          closeDetailOverlay={this.resetDetailed}
        />
      );
      if (this.state.showDataDownload
          && this.props.computedData.hasError === false
          && this.props.computedData.value.status === LoadableStatus.Present
        ) {
        const sectionsHS = [
          'Textiles',
          'Agriculture',
          'Stone',
          'Minerals',
          'Metals',
          'Chemicals',
          'Vehicles',
          'Machinery',
          'Electronics',
          'Other',
          'Services',
        ];
        const sectionsSITC = [
          'Food',
          'Beverages',
          'Crude Materials',
          'Fuels',
          'Vegetable Oils',
          'Chemicals',
          'Material manufacturers',
          'Machinery and vehicles',
          'Other manufacturers',
          'Unspecified',
          'Services',
        ];
        const csvData: object[] = [];
        this.props.computedData.value.data.ribbons.forEach(({longLabel, dataPoints, section}) => {
          const Name = longLabel;
          dataPoints.forEach(({detailOverlayInfo}) => {
            const dataRows: {[key: string]: any} = {};
            detailOverlayInfo.forEach(({label, value}) => {
              if (typeof value === 'string' || typeof value === 'number') {
                dataRows[label] = value;
              }
            });
            const Sector = productClass === ProductClass.HS ? sectionsHS[section] : sectionsSITC[section];
            if (Sector) {
              csvData.push({Name, ...dataRows, Sector});
            } else {
              csvData.push({Name, ...dataRows});
            }
          });
        });
        const title = this.state.graphTitle.length ? this.state.graphTitle : 'data';
        dataDownload = (
          <TriggerDataDownload
            data={csvData}
            graphTitle={title}
            closeOverlay={this.dismissDataDownload}
          />
        );
      } else {
        dataDownload = null;
      }
    } else {
      stackChartElem = null;
      dataDownload = null;
      share = (
        <Share
          launchShare={null}
          launchExports={null}
          showDataDownload={null}
          dataNotesProps={dataNotesProps}
          isTutorialModalOpen={this.props.isTutorialModalOpen}
          setIsTutorialModalOpen={this.props.setIsTutorialModalOpen}
          closeDetailOverlay={this.resetDetailed}
        />
      );
    }
    /* End of actual chart */

    /* Start of product class selector */
    const productClassSelector = (
      <RadioSelector
        tooltipText={__lexiconText('applicationWide.productClassSelector.tooltipText')}
        mainLabel={__lexiconText('applicationWide.productClassSelector.mainLabel')}
        choices={productClassChoices}
        selected={productClass}
        onClick={onProductClassChange}
      />
    );
    /* End of product class selector */

    /* Start of detail level selector */
    let detailLevelSelector: React.ReactNode;
    if (routingInputValidity.isValid === true) {
      if (routingInputValidity.extraInfo.subject === GraphSubject.Country) {
        detailLevelSelector = (
          <RadioSelector
            tooltipText={__lexiconText('applicationWide.countryDetailLevelSelector.tooltipText')}
            mainLabel={__lexiconText('applicationWide.countryDetailLevelSelector.mainLabel')}
            choices={countryDetailLevelChoices}
            selected={countryLevel}
            onClick={this.changeCountryLevel}
          />
        );

      } else {
        const choices: Array<IChoice<ProductLevel>> = (productClass === ProductClass.HS) ?
                                                        expandedProductDetailLevelChoices :
                                                        productDetailLevelChoices;
        detailLevelSelector = (
          <RadioSelector
            tooltipText={__lexiconText('applicationWide.productDetailLevelSelector.tooltipText')}
            mainLabel={__lexiconText('applicationWide.productDetailLevelSelector.mainLabel')}
            choices={choices}
            selected={productLevel}
            onClick={this.changeProductLevel}
          />
        );
      }
    } else {
      detailLevelSelector = null;
    }
    /* End of detail level selector */

    /* Start of Trade flow selector */
    const tradeFlowSelector = (
      <RadioSelector
        tooltipText={__lexiconText('applicationWide.tradeFlowSelector.tooltipText')}
        mainLabel={__lexiconText('applicationWide.tradeFlowSelector.mainLabel')}
        choices={tradeFlowChoices}
        selected={tradeFlow}
        onClick={onTradeFlowChange}
      />
    );
    /* End of Trade flow selector */

    /* Start of layout selector */
    const layoutSelector = (
      <RadioSelector
        tooltipText={__lexiconText('layoutSelector.tooltipText')}
        mainLabel={__lexiconText('layoutSelector.mainLabel')}
        choices={layoutChoices}
        selected={layout}
        onClick={this.onLayoutChange}
      />
    );
    /* End of layout selector */

    /* Start of ordering selector */
    const orderingSelector = (
      <RadioSelector
        tooltipText={__lexiconText('orderingSelector.tooltipText')}
        mainLabel={__lexiconText('orderingSelector.mainLabel')}
        choices={orderingChoices}
        selected={ordering}
        onClick={this.onOrderingChange}
      />
    );
    /* End of ordering selector */

    /* Start of highlight dropdown */
    let dropdownOptions: IDropdownOption[];
    if (graphStatus.status === GraphStatusCode.Success) {
      ({value: {dropdownOptions}} = graphStatus);
    } else {
      dropdownOptions = [];
    }
    const highlightDropdown = (
      <HighlightDropdown
        label={__lexiconText('applicationWide.highlightDropdown.mainLabel')}
        tooltipText={__lexiconText('applicationWide.highlightDropdown.tooltipText')}
        isClearable={true}
        options={dropdownOptions}
        value={highlighted}
        onChange={this.updateProductHighlight}
        DropdownComponent={Dropdown as any}
        DropdownContainerComponent={RightLowerControlContainer}
        vizType={VizType.Stack}
      />
    );
    /* End of highlight dropdown */

    /* Start of tooltips */
    let tooltips: JSX.Element | null;
    // If there's tooltips to show, use the `TooltipsContainer` component:
    if ((graphStatus.status === GraphStatusCode.Success ||
          graphStatus.status === GraphStatusCode.LoadingAfterSuccess) &&
        (hovered !== undefined || selected !== undefined || highlighted !== undefined)) {
      const {value: {tooltipMap, ribbons}} = graphStatus;

      tooltips = (
        <Tooltips
          tooltipMap={tooltipMap} hovered={hovered} selected={selected}
          ribbons={ribbons} highlighted={highlighted}
        />
      );
    } else {
      tooltips = null;
    }
    /* End of tooltips */

    /* Start of axis projections */
    let axisProjections: JSX.Element | null;
    if (graphStatus.status === GraphStatusCode.Success ||
        graphStatus.status === GraphStatusCode.LoadingAfterSuccess) {
      const {value: {tooltipMap, ribbons}} = graphStatus;
      axisProjections = (
        <AxisProjections
          hovered={hovered} selected={selected}
          svgWidth={svgWidth} tooltipMap={tooltipMap}
          ribbons={ribbons} highlighted={highlighted}
        />
      );
    } else {
      axisProjections = null;
    }
    /* End of axis projections */

    /* Start of detail overlay */
    let detailOverlay: JSX.Element | null;
    if ((graphStatus.status === GraphStatusCode.Success ||
          graphStatus.status === GraphStatusCode.LoadingAfterSuccess) &&
          detailed !== undefined) {

      const {value: {tooltipMap}} = graphStatus;

      detailOverlay = (
        <DetailOverlayContainer
          tooltipMap={tooltipMap}
          detailed={detailed}
          hideOverlay={this.resetDetailed}
          rememberEl={this.rememberDetailOverlayEl}/>
      );
    } else {
      detailOverlay = null;
    }
    /* End of detail overlay */

    /* Start of year selector */
    const yearSelector = (
      <YearSelector
        type={TimelineType.YearRange}
        endYear={endYear} startYear={startYear}
        productClass={productClass}
        onStartYearChange={onStartYearChange}
        onEndYearChange={onEndYearChange}
      />
    );
    /* End of year selector */

    //#region Category selector
    let categorySelector: JSX.Element;
    if (routingInputValidity.isValid === true) {
      if (routingInputValidity.extraInfo.subject === GraphSubject.Product) {
        categorySelector = (
          <Suspense fallback={(<CategorySelectorContainer/>)}>
            <ProductCategorySelector
              deselectedCategories={deselectedCategories}
              productClass={productClass}
              setDeselected={this.setDeselectedCategories}
              resetDeselected={this.resetDeselectedCategories}
              isServicesEnabled={isServicesEnabled}
              isServicesNotAvailableForAllYears={isServicesNotAvailableForAllYears}
            />
          </Suspense>
        );
      } else {
        categorySelector = (
          <RegionSelector deselectedCategories={deselectedCategories}
            setDeselected={this.setDeselectedCategories}
            resetDeselected={this.resetDeselectedCategories}
          />

        //   <RegionSelector
        //   selectedCategories={selectedCategories}
        //   setSelected={setSelectedCategories}
        // />
        );
      }

    } else {
      categorySelector = (
        <CategorySelectorContainer />
      );
    }
    //#endregion

    const vizSettingsNodeRef = document.querySelector<HTMLElement>(`#${vizSettingsPortalId}`);

    let vizSettings: React.ReactElement<any> | null;
    if (vizSettingsNodeRef) {
      vizSettings = ReactDOM.createPortal((
        <>
          {productClassSelector}
          {layoutSelector}
          {tradeFlowSelector}
          {orderingSelector}
          {detailLevelSelector}
        </>
      ), vizSettingsNodeRef);
    } else {
      vizSettings = null;
    }

    let facet = determineDataFacet({location: country, product, target: this.state.mirrored.target, partner: partner});

    const titleInfo = {
      location: country,
      queryLevel: queryLevel,
      product: product,
      facet: facet,
      partner: partner
    };

    let chartTitleElem: JSX.Element | null = null;
    if(titleInfo) {
      chartTitleElem = 
        <NewGraphTitle
        country={titleInfo.location}
        queryLevel={titleInfo.queryLevel}
        product={titleInfo.product}
        startYear={startYear}
        endYear={endYear}
        tradeDirection={props.tradeDirection}
        productClass={props.productClass}
        // setTitle={() => setNewChartTitle}
        vizType={VizType.Stack}
        facet={titleInfo.facet}
        partner={titleInfo.partner}
        setTitle={(val: string) => this.updateGraphTitle(val)}
      />
  
    }

    return (
      <>
        {chartTitleElem}
        {/* <ChartHeader>
          <StackGraphTitle
            country={country}
            queryLevel={queryLevel}
            otherCountry={props.partner}
            product={product}
            tradeDirection={props.tradeDirection}
            productClass={props.productClass}
            startYear={startYear}
            endYear={endYear}
            target={this.state.mirrored.target}
            setTitle={(val: string) => this.updateGraphTitle(val)}
          />
        </ChartHeader> */}
        <StackChartContainer ref={this.rememberChartContainer} onClick={this.resetSelected}>
          {stackChartElem}
        </StackChartContainer>
        <NonInteractiveChartContainer>
          {axisProjections}
        </NonInteractiveChartContainer>
        <NonInteractiveChartContainer>
          {loadingSpinner}
          {errorOverlay}
        </NonInteractiveChartContainer>
        {categorySelector}
        <HighlightContainer>
          {highlightDropdown}
        </HighlightContainer>
        <YearSelectorContainerWithoutPlayButton>
          {yearSelector}
        </YearSelectorContainerWithoutPlayButton>
        {vizSettings}
        {share}
        {tooltips}
        {detailOverlay}
        {dataDownload}
      </>
    );
  }
}

const mapStateToProps: () => MapStateToProps<IStateProps, IOwnProps, IRootState> = () => {
  const getCountryMetadata = getMainThreadCountryMetadataSelector();
  const getProductMetadata = getMainThreadProductMetadataSelector();
  const getGroupsMetadata = getMainThreadGroupsMetadataSelector();
  const getGroupMembersMetadata = getMainThreadGroupMembersMetadataSelector();
  return (rootState: IRootState, ownProps: IOwnProps) => {


    const {
      country, product, queryLevel, target, partner, productClass,
      onEndYearChange, onTradeFlowChange, onProductClassChange,
    } = ownProps;

    const uiState = getUIState(rootState);
    const {
      svgWidth, svgHeight, svgTop, svgLeft, deselectedCategories,
      inflationAdjustment, populationAdjustment, layout,
      productLevel, countryLevel, ordering,
    } = uiState;
    const hashInput = getHashInputFromRoutingAndUIState(ownProps, uiState);

    const computedData = getMergedData(rootState, hashInput);
    const countryMetadata = getCountryMetadata(rootState, {});
    const productMetadata = getProductMetadata(rootState, {productClass});
    const groupsMetadata = getGroupsMetadata(rootState, {});
    const groupMembersMetadata = getGroupMembersMetadata(rootState, {});

    return {
      svgWidth, svgHeight, svgTop, svgLeft, deselectedCategories,
      onEndYearChange, onTradeFlowChange, onProductClassChange,
      inflationAdjustment, populationAdjustment, layout,
      productLevel, countryLevel, ordering,
      computedData,
      updateType: getUpdateType(rootState),
      routingInputValidity: checkForInvalidRoutingInput(({country, queryLevel, partner, product, target})),
      countryMetadata,
      productMetadata,
      groupsMetadata,
      groupMembersMetadata,
      announcementBannerShown: rootState.miscellaneous.announcementBannerShown,
    };
  };
};

const mapDispatchToProps: MapDispatchToProps<IGraphDispatchProps, IOwnProps> =
  (dispatch: Dispatch) => {

    return {
      dispatchToWorkerStore: (action: AnyAction) => postMessageToMergeWorker(action),
      dispatchToMainStore: (action: AnyAction) => dispatch(action),
      dispatchToBothStores: (action: AnyAction) => {
        dispatch(action);
        postMessageToMergeWorker(action);
      },
    };
  };

export default connect(mapStateToProps, mapDispatchToProps)(Stack);
