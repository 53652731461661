import {
  fromEvent,
} from 'rxjs';
import {
  buffer,
  debounceTime,
  filter,
  tap,
} from 'rxjs/operators';
export const transitionDuration = 350; // in milliseconds

export const disabledCategoryColor = 'rgb(204, 204, 204)';
export const unselectedCategoryColor = 'rgb(183, 183, 183)';

export enum SpotlightMode {
  // Spotlight mode "on" means clicking on a category will deselect all other
  // categories. "off" means clicking on a category will deselect/select just
  // that category:
  On = 'SpotlightOn',
  Off = 'SpotlightOff',
}

export const getSingleAndDoubleClickStreams = (el: HTMLElement) => {
  const rawClick$ = fromEvent(el, 'click').pipe(
    tap((e: MouseEvent) => {
      e.stopPropagation();
      e.preventDefault();
    }),
  );
  const debouncedRawClicks$ = rawClick$.pipe(debounceTime(250));
  const bufferedClick$ = rawClick$.pipe(buffer(debouncedRawClicks$));
  const doubleClick$ = bufferedClick$.pipe(
    filter((arr: MouseEvent[]) => arr.length >= 2),
  );
  const singleClick$ = bufferedClick$.pipe(
    filter((arr: MouseEvent[]) => arr.length < 2),
  );

  return {
    singleClick$, doubleClick$,
  };

};
