import React from 'react';
import { Root } from '../newCategorySelector/RegionContainer';

interface IProps {
  color: string;
  text: string;
}

export default class extends React.PureComponent<IProps> {
  render() {
    const {color, text} = this.props;
    const style = {
      backgroundColor: color,
    };
    return (
      <Root style={style}>
        {text}
      </Root>
    );
  }
}
