export interface IChoiceGetElementInput {
  isSelected: boolean; isEnabled: boolean;
}

export interface IChoice {
  value: string;
  getElement: (input: IChoiceGetElementInput) => React.ReactElement<any> | null;
  label: string;
  color: string;
}

export enum SpotlightMode {
  // Spotlight mode "on" means clicking on a category will deselect all other
  // categories. "off" means clicking on a category will deselect/select just
  // that category:
  On = 'SpotlightOn',
  Off = 'SpotlightOff',
}
