import getReducer, {
  IBaseState,
} from '../../sharedComponents/getNewDataCache';
import {
  apiBaseURL,
  fetchJSON,
} from '../../Utils';
import {
  IWorkerRootState,
} from '../workerRootState';

// Mapping from 4-digit-level product ID to section-level ID:
interface IAPIResponse {
  data: {
    [countryId: string]: number;
  };
}
export type IMappingData = Map<number, number>;

const getDataFromAPIResponse = ({data}: IAPIResponse) => {
  const pairs: Array<[number, number]> = Object.entries(data).map(
    ([key, value]) => ([parseInt(key, 10), value] as [number, number]),
  );
  return new Map(pairs);
};
export type IState = IBaseState<IMappingData>;

const FETCH_BEGIN = 'COUNTRY_LEVEL_MAPPING_FETCH_BEGIN';
const FETCH_SUCCESS = 'COUNTRY_LEVEL_MAPPING_FETCH_SUCCESS';
const FETCH_FAIL = 'COUNTRY_LEVEL_MAPPING_FETCH_FAIL';
const FETCH_IF_NEEDED = 'COUNTRY_LEVEL_MAPPING_FETCH_IF_NEEDED';

type IHash = {};

const {
  reducer,
  fetchIfNeeded,
  fetchDataEpic,
  getDataSelector,
} = getReducer<
  IMappingData,
  IWorkerRootState,
  typeof FETCH_IF_NEEDED,
  typeof FETCH_BEGIN,
  typeof FETCH_SUCCESS,
  typeof FETCH_FAIL,
  IHash,
  IAPIResponse
>({
  fetchIfNeededName: FETCH_IF_NEEDED,
  fetchBeginName: FETCH_BEGIN,
  fetchSuccessName: FETCH_SUCCESS,
  fetchFailName: FETCH_FAIL,
  getDataCache: (rootState: IWorkerRootState) => rootState.countryLevelMapping,
  getFetchPromise: () => fetchJSON<IAPIResponse>(`${apiBaseURL}/metadata/location/hierarchy`),
  hashFunction: () => '',
  getDataFromAPIResponse,
});

export default reducer;

export {fetchIfNeeded, fetchDataEpic, getDataSelector};
