import {
  ProductClass,
  QueryLevel
} from '../../graphQL/types/shared';
import { generateStringProductId } from '../../graphQL/Utils';
import getYearlyDataCache, {
  IBaseState,
} from '../../sharedComponents/yearlyDataCache';
import {
  apiBaseURL,
  fetchJSON,
  ICCPYDatum,
} from '../../Utils';
import {
  IWorkerRootState,
} from '../workerRootState';

export type IState = IBaseState<ICCPYDatum>;

interface IHashInput {
  productClass: ProductClass;
  country: number;
  product: number;
}

interface IAPIResponse {
  data: ICCPYDatum[];
}

export const defaultHashFunction =
  ({productClass, country, queryLevel, product}: IHashInput) => `${productClass}_${queryLevel}_${country}_${product}`;

const FETCH_BEGIN = 'COUNTRY_COUNTRY_PRODUCT_YEAR_BY_COUNTRY_PRODUCT_FETCH_BEGIN';
const FETCH_SUCCESS = 'COUNTRY_COUNTRY_PRODUCT_YEAR_BY_COUNTRY_PRODUCT_FETCH_SUCCESS';
const FETCH_FAIL = 'COUNTRY_COUNTRY_PRODUCT_YEAR_BY_COUNTRY_PRODUCT_FETCH_FAIL';
const FETCH_IF_NEEDED = 'COUNTRY_COUNTRY_PRODUCT_YEAR_BY_COUNTRY_PRODUCT_FETCH_IF_NEEDED';

const {
  reducer,
  fetchDataEpic,
  fetchIfNeeded,
  getDataStatusSelector,
  getYearsStatusSelector,
} = getYearlyDataCache<
  IWorkerRootState,
  ICCPYDatum,
  typeof FETCH_IF_NEEDED,
  typeof FETCH_BEGIN,
  typeof FETCH_SUCCESS,
  typeof FETCH_FAIL,
  IHashInput,
  IAPIResponse
>({
  fetchIfNeededName: FETCH_IF_NEEDED,
  fetchBeginName: FETCH_BEGIN,
  fetchSuccessName: FETCH_SUCCESS,
  fetchFailName: FETCH_FAIL,
  outerHashFunction: defaultHashFunction,
  getCacheFromRootState: (rootState: IWorkerRootState) => rootState.countryCountryProductYearByCountryProduct,
  getFetchPromise: ({productClass, country, queryLevel, product, level}: IHashInput) => {
    const productClassPhrase = (productClass === ProductClass.HS) ?
                                'hs_product' : 'sitc_product';

    const stringProductId = generateStringProductId({productClass, id: product});

    // let fetchLevelPhrase: string;
    // if (level === Level.section || level === Level.twoDigit || level === Level.fourDigit) {
    //   fetchLevelPhrase = '4digit';
    // } else if (level === Level.sixDigit) {
    //   fetchLevelPhrase = '6digit';
    // } else {
    let apiURL;

    if(queryLevel === QueryLevel.Location) {

      apiURL = `${apiBaseURL}/data/location/${country}/partners_by_${productClassPhrase}/${product}/?level=country`;

    } else if(queryLevel === QueryLevel.Group) {

      apiURL = `${apiBaseURL}/data/groups/group_partner_product_year/${queryLevel}-${country}/product/${stringProductId}/?aggregate=1`;

    }


    return fetchJSON<IAPIResponse>(apiURL);


  },
  getDataFromAPIResponse: ({data}: IAPIResponse) => data,
});

export default reducer;
export {fetchDataEpic, fetchIfNeeded, getDataStatusSelector, getYearsStatusSelector};
