import {
  getLinearScale,
} from '../../NonConstantUtils';
import {
  formatTradeValue,
} from '../../numberFormatters';
import {
  toPercentageString,
} from '../../Utils';
import {
  Layout,
} from '../../workerStore/stack/Utils';

interface ITick {
  position: number;
  label: string;
}

interface IOutput {
  xTicks: ITick[];
  yTicks: ITick[];
}

interface IInput {
  xAxisMax: number;
  xAxisMin: number;
  yAxisMax: number;
  yAxisMin: number;
  layout: Layout;
  svgWidth: number;
  svgHeight: number;
}
const getTicksInfo = (input: IInput): IOutput => {
  const {
    xAxisMin, xAxisMax, yAxisMin, yAxisMax,
    svgWidth, svgHeight, layout,
  } = input;
  const xScale = getLinearScale([xAxisMin, xAxisMax], [0, svgWidth]);
  const yScale = getLinearScale([yAxisMin, yAxisMax], [svgHeight, 0]);

  const integerXTickValues: number[] = xScale.ticks().filter(value => Number.isInteger(value));
  const xTicks: ITick[] = integerXTickValues.map(year => ({
    position: xScale(year),
    label: year.toString(),
  }));

  const yTicks: ITick[] = yScale.ticks().map(value => ({
    position: yScale(value),
    label: (layout === Layout.Share) ? toPercentageString(value) : formatTradeValue(value),
  }));

  return {xTicks, yTicks};
};

export default getTicksInfo;
