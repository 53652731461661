import styled from 'styled-components';
import {
  ChartContainer as ChartContainerBase,
} from '../viz/VizGrid';
import {
  bottomMargin,
  leftMargin,
} from './Utils';

// Add internal grid within the `ChartContainer`:
export const StackChartContainer = styled(ChartContainerBase)`
  display: grid;
  grid-template-rows: 1fr ${bottomMargin}px;
  grid-template-columns: ${leftMargin}px 1fr;
`;

export const StackChartRoot = styled.div`
  grid-row: 1;
  grid-column: 2;
  position: relative;
`;

export const YAxisContainer = styled.div`
  position: relative;
  grid-row: 1;
  grid-column: 1;
`;

export const XAxisContainer = styled.div`
  position: relative;
  grid-row: 2;
  grid-column: 2;
`;

export const NonInteractiveChartContainer = styled(StackChartContainer)`
  pointer-events: none;
  position: relative;
`;
