import styled from 'styled-components';
import { gridSmallMediaSize } from '../../countryProfiles/Grid';
import { secondaryGray } from '../../cssVariables';
import {
  transitionDuration,
} from './Utils';

const root2 = Math.sqrt(2);
const roundedRoot2 = Math.round(root2 * 100) / 100;
const strikeThroghLinePaddingFactor = 1;

const horizontalDisplacement = 6; // in percentage
const verticalDisplacement = 37; // in percentage
const anchorTopMargin = 13; // in pixels

export const hideIsolateTooltipBackgroundColor = 'rgb(119, 124, 142)';

export const Anchor = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity ${transitionDuration}ms ease-in-out;
  position: relative;

  --strike-through-display: none;

  margin: ${anchorTopMargin}px 5px 0;

  &::after {
    display: block;
    position: absolute;
    content: '';
    left: -${horizontalDisplacement}%;
    right: ${horizontalDisplacement}%;
    top: ${verticalDisplacement}%;
    height: 2px;
    background-color: rgb(115, 115, 115);
    transform: rotate(45deg) scaleX(${roundedRoot2 * strikeThroghLinePaddingFactor});
    opacity: var(--strike-through-opacity);
  }

  @media (max-width: ${gridSmallMediaSize}px) {
    margin: ${anchorTopMargin}px 3px 0;
  }
`;

export const Root = styled.div`
  position: relative;
  height: 100%;
`;

const arrowOuterHeight = 11; // in px
const tooltipBorderColor = 'rgba(255, 255, 255, 0.2)';

// This is needed to prevent the tooltip from disappearing during the time the
// user moves the mouse pointer from the category icon/region name to the
// tooltip's border:
const tooltipChoiceContainerOverlap = 5; // in px

export const TooltipContainer = styled.div`
  position: absolute;
  bottom: 100%;
  transform: translateX(-50%) translateY(${- arrowOuterHeight + anchorTopMargin + tooltipChoiceContainerOverlap}px);
  left: 50%;
  background-color: ${secondaryGray};
  color: #fff;
  font-size: 0.95rem;

  &::before,
  &::after {
    content: '';
    display: block;
    width: 0;
    height: 0;
    border-left: var(--triangle-half-base) solid transparent;
    border-right: var(--triangle-half-base) solid transparent;
    border-top: var(--triangle-height) solid var(--triangle-color);
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
  }

  /* Triangle fill: */
  &::after {
    --triangle-half-base: ${arrowOuterHeight - 1}px;
    --triangle-height: ${arrowOuterHeight - 1}px;
    --triangle-color: ${secondaryGray};
  }

  /* Triangle stroke: */
  &::before {
    --triangle-half-base: ${arrowOuterHeight}px;
    --triangle-height: ${arrowOuterHeight}px;
    --triangle-color: ${tooltipBorderColor};
  }
`;

const edgePadding = 0.6; // in rem
export const TooltipTitle = styled.div`
  padding: 0.75rem ${edgePadding}rem 1.25rem;
  text-transform: uppercase;
  text-align: center;
  white-space: nowrap;
`;

export const TooltipControls = styled.ul`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 14px;
`;

export const TooltipControlItem = styled.li`
  display: flex;
  align-items: center;
  cursor: pointer;

  --selection-filter: unset;

  &:hover {
    --selection-filter: brightness(80%);
  }

  &:first-child {
    margin-right: 1.25rem;
    padding-left: ${edgePadding}rem;
  }

  &:last-child {
    padding-right: ${edgePadding}rem;
  }
`;

export const TooltipControlItemCircle = styled.div`
  border-radius: 50%;
  width: 1.1rem;
  height: 1.1rem;
  border: 1px solid white;
  display: inline-block;
  background-color: transparent;
  filter: var(--selection-filter);
`;

export const TooltipControlItemCircleSelected = styled(TooltipControlItemCircle)`
  background-color: white;
  border: 1px solid white;
  box-shadow: inset 0 0 0 2px rgb(119, 124, 142);
`;

export const TooltipControlItemText = styled.div`
  display: inline-block;
  padding: 0.25rem 0 0.1rem 0.2rem;
  filter: var(--selection-filter);
`;
