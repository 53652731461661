import React, {useEffect, useRef, useState} from 'react';
import {
  ReactSelectProps,
} from 'react-select';
import {
  Subscription,
} from 'rxjs';
import Tooltip, {
  defaultTooltipDelay,
  getTooltipVisibilityStream,
} from '../sharedComponents/UITooltip';
import {
  highlightDropdownFilterFunction,
  IDropdownOption,
} from '../Utils';
import { VizType } from '../viz/Utils';
import {
  Label,
  Root,
  SelectButton,
} from './NewHighlightDropdown';

type DisplayedValue = {
  isClearable: true;
  value: number | undefined;
} | {
  isClearable: false;
  value: number;
};

type IProps = {
  label: string;
  tooltipText: string;
  options: IDropdownOption[];
  onChange: (input: IDropdownOption | null) => void;
  // `DropdownComponent` is something like `styled(Select)`stome style``:
  DropdownComponent: React.ComponentType<ReactSelectProps>
  DropdownContainerComponent: any;
  optionRenderer?: (option: IDropdownOption) => JSX.Element;
  valueRenderer?: (option: IDropdownOption) => JSX.Element;
  vizType: VizType;
} & DisplayedValue;

export default (props: IProps) => {
  const {
    label, options, value, isClearable, tooltipText,
    DropdownComponent, DropdownContainerComponent,
    onChange, optionRenderer, valueRenderer, vizType,
  } = props;

  const [tooltipShown, setTooltipShown] = useState<boolean>(false);
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

  const dropdownContainerEl = useRef<HTMLElement | null>(null);
  let showOrHideSubscription: Subscription | undefined;

  useEffect( () => {
    if (dropdownContainerEl && dropdownContainerEl.current) {
      const showOrHide$ = getTooltipVisibilityStream(dropdownContainerEl.current, defaultTooltipDelay);
      showOrHideSubscription = showOrHide$.subscribe(isTooltipShown => setTooltipShown(isTooltipShown));
    }
    return () => {
      if (showOrHideSubscription !== undefined) {
        showOrHideSubscription.unsubscribe();
      }
    };
  }, [dropdownContainerEl]);

  const tooltip =  tooltipShown ? (
    <Tooltip>{tooltipText}</Tooltip>
  ) : null;

  const style: React.CSSProperties = isMenuOpen === false &&
    (value === undefined || vizType === VizType.Rings) ? {
    opacity: 0,
  } : {
    opacity: 1,
    width: 300,
  };

  return (
    <>
      <Label>{label}</Label>
      <SelectButton
        dangerouslySetInnerHTML={{__html: require('../countryProfiles/countrySelector/countrySearch.svg')}}
      />
      <DropdownContainerComponent ref={dropdownContainerEl} style={{position: 'absolute', bottom: 0, right: 0}}>
        <Root style={style}>
          <DropdownComponent
            options={options} value={value} onChange={onChange}
            optionRenderer={optionRenderer} valueRenderer={valueRenderer}
            clearable={isClearable} filterOption={highlightDropdownFilterFunction}
            onOpen={() => setIsMenuOpen(true)}
            onClose={() => setIsMenuOpen(false)}
            menuStyle={{maxHeight: 80}}
          />
          {tooltip}
        </Root>
      </DropdownContainerComponent>
    </>
  );
};
