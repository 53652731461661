import React from 'react';
import styled from 'styled-components';
import {
  failIfValidOrNonExhaustive,
} from '../../Utils';
import {
  CategorySelectorContainer,
  vizGridZIndices,
} from '../../viz/VizGrid';
import {
  SpotlightMode,
} from './Utils';

const Container = styled(CategorySelectorContainer)`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: ${vizGridZIndices.categorySelector};

  @media (max-width: 1160px) {
    justify-content: flex-start;
  }
`;

export interface IChoiceGetElementInput {
  isSelected: boolean; isEnabled: boolean;
}
export interface IChoice {
  value: number;
  getElement: (input: IChoiceGetElementInput) => React.ReactNode;
  label: string;
  color: string;
}

export interface IRenderChoicesInput {
  choices: IChoice[];
  deselectedCategories: number[];
  onShowHideClick: (value: number) => void;
  onIsolateClick: (value: number) => void;
  spotlightMode: SpotlightMode;
}

export interface IProps {
  // List of values for all choices:
  allPossibleValues: number[];

  choices: IChoice[];
  deselectedCategories: number[];
  setDeselected: (ids: number[]) => void;
  resetDeselected: () => void;
  renderChoices: (input: IRenderChoicesInput) => React.ReactNode;
  spotlightMode: SpotlightMode;
  setSpotlightMode: (mode: SpotlightMode) => void;
}

export default class extends React.Component<IProps, {}> {
  private onShowHideClick = (value: number) => {
    const {
      deselectedCategories: prevDeselectedCategories,
      setDeselected,
      spotlightMode: prevSpotlightMode,
      setSpotlightMode,
    } = this.props;

    const nextSpotlightMode = SpotlightMode.Off;

    let nextDeselectedCategories: number[];
    if (prevSpotlightMode === SpotlightMode.On) {
      nextDeselectedCategories = [value];
    } else {
      if (prevDeselectedCategories.includes(value)) {
        nextDeselectedCategories = prevDeselectedCategories.filter(val => val !== value);
      } else {
        nextDeselectedCategories = [...prevDeselectedCategories, value];
      }
    }

    setSpotlightMode(nextSpotlightMode);
    setDeselected(nextDeselectedCategories);
  }

  private onIsolateClick = (value: number) => {
    const {spotlightMode: prevSpotlightMode, setSpotlightMode} = this.props;
    const {allPossibleValues, setDeselected, deselectedCategories: prevDeselectedCategories} = this.props;
    let nextSpotlightMode: SpotlightMode;
    let nextDeselectedCategories: number[];
    if (prevSpotlightMode === SpotlightMode.On) {
      const isClickedCategoryIsolated = !prevDeselectedCategories.includes(value);
      if (isClickedCategoryIsolated) {
        // If the user clicks on the currently isolated mode, turn isolate mode off:
        nextSpotlightMode = SpotlightMode.Off;
        nextDeselectedCategories = [];
      } else {
        // Otherwise, isolate the clicked category:
        nextSpotlightMode = SpotlightMode.On;
        nextDeselectedCategories = allPossibleValues.filter(val => val !== value);
      }
    } else if (prevSpotlightMode === SpotlightMode.Off) {
      nextSpotlightMode = SpotlightMode.On;
      nextDeselectedCategories = allPossibleValues.filter(val => val !== value);
    } else {
      failIfValidOrNonExhaustive(prevSpotlightMode, 'Invalid spotlight mode');
      // These lines will never be executed:
      nextSpotlightMode = SpotlightMode.Off;
      nextDeselectedCategories = [];
    }

    setSpotlightMode(nextSpotlightMode);
    setDeselected(nextDeselectedCategories);
  }

  render() {
    const {
      choices, deselectedCategories, renderChoices, spotlightMode,
    } = this.props;

    const choiceElems = renderChoices({
      choices, deselectedCategories,
      onShowHideClick: this.onShowHideClick,
      onIsolateClick: this.onIsolateClick,
      spotlightMode,
    });

    return (
      <Container>
        {choiceElems}
      </Container>
    );
  }
}
