import {
  MutableRefObject,
  useEffect,
} from 'react';
import {
  useState,
} from 'react';
import {
  fromEvent,
} from 'rxjs';
import {
  Subscription,
} from 'rxjs';
import {
  buffer,
  debounceTime,
  filter,
  tap,
} from 'rxjs/operators';
import {
  IChoice,
  SpotlightMode,
} from './otherTypes';

export const transitionDuration = 350; // in milliseconds

export const disabledCategoryColor = 'rgb(204, 204, 204)';
export const unselectedCategoryColor = 'rgb(183, 183, 183)';

export const getSingleAndDoubleClickStreams = (el: HTMLElement) => {
  const rawClick$ = fromEvent(el, 'click').pipe(
    tap((e: MouseEvent) => {
      e.stopPropagation();
      e.preventDefault();
    }),
  );
  const debouncedRawClicks$ = rawClick$.pipe(debounceTime(250));
  const bufferedClick$ = rawClick$.pipe(buffer(debouncedRawClicks$));
  const doubleClick$ = bufferedClick$.pipe(
    filter((arr: MouseEvent[]) => arr.length >= 2),
  );
  const singleClick$ = bufferedClick$.pipe(
    filter((arr: MouseEvent[]) => arr.length < 2),
  );

  return {
    singleClick$, doubleClick$,
  };
};

export const useIsolateBehaviorForTooltips = (input: {
    onShowHideClick: (value: string) => void;
    onIsolateClick: (value: string) => void;
    isEnabled: boolean;
    choice: IChoice
    DOMElemRef: MutableRefObject<HTMLElement | null>,
  }) => {

  const {DOMElemRef, choice, isEnabled} = input;

  const showHide = () => {
    const {value} = choice;
    if (isEnabled === true) {
      input.onShowHideClick(value);
    }
  };

  const onShowHideClick = (e: React.MouseEvent<any>) => {
    e.preventDefault();
    e.stopPropagation();
    showHide();
  };

  const isolate = () => {
    const {value} = choice;
    if (isEnabled === true) {
      input.onIsolateClick(value);
    }
  };

  const onIsolateClick = (e: React.MouseEvent<any>) => {
    e.preventDefault();
    e.stopPropagation();
    isolate();
  };

  useEffect(() => {
    const {current} = DOMElemRef;
    let iconDoubleClickSubscription: Subscription | undefined;
    let iconSingleClickSubscription: Subscription | undefined;
    if (current !== null) {
      const {singleClick$, doubleClick$} = getSingleAndDoubleClickStreams(current);
      iconDoubleClickSubscription = doubleClick$.subscribe(isolate);
      iconSingleClickSubscription = singleClick$.subscribe(showHide);
    }
    return () => {
      if (iconDoubleClickSubscription !== undefined) {
        iconDoubleClickSubscription.unsubscribe();
      }
      if (iconSingleClickSubscription !== undefined) {
        iconSingleClickSubscription.unsubscribe();
      }
    };
  });

  return {
    onShowHideClick, onIsolateClick,
  };
};

interface WithID {
  id: string;
}

export const useIsolateBehaviorForSelector = (input: {
    selectedCategories: string[],
    allCategories: WithID[],
    setSelected: (categories: string[]) => void,
  }) => {

  const {selectedCategories, allCategories, setSelected} = input;
  const [spotlightMode, setSpotlightMode] = useState<SpotlightMode>(SpotlightMode.Off);
  const [spotlightId, setSpotlightId] = useState<string | undefined>(undefined);

  const onShowHideClick = (id: string) => {
    let nextSpotlightId: string | undefined;
    let nextSelectedCategories: string[];
    if (spotlightId === undefined) {
      // There is no highlighted product
      // keep it that way
      nextSpotlightId = undefined;
      // toggle the current product
      if (selectedCategories.includes(id)) {
        nextSelectedCategories = selectedCategories.filter(val => val !== id);
      } else {
        nextSelectedCategories = [...selectedCategories, id];
      }
    } else {
      // there is a highlighted product
      // if it is this product
      // show all categories except this one
      if (id === spotlightId) {
        nextSpotlightId = undefined;
        const allIds = allCategories.map(choice => choice.id);
        nextSelectedCategories = allIds.filter(val => val !== id);
      } else {
        // it is not this product. we will still remove it but
        // first we need to add it to the selected categories
        nextSpotlightId = undefined;
        nextSelectedCategories = [spotlightId, id];
      }
    }
    if (nextSelectedCategories.length === 1) {
      setSpotlightMode(SpotlightMode.On);
    } else {
      setSpotlightMode(SpotlightMode.Off);
    }
    setSpotlightId(nextSpotlightId);
    setSelected(nextSelectedCategories);
  };

  const onIsolateClick = (id: string) => {
    let nextSpotlightId: string | undefined;
    let nextSelectedCategories: string[];
    if (spotlightId !== id) {
      nextSpotlightId = id;
      nextSelectedCategories = [id];
    } else {
      nextSpotlightId = undefined;
      nextSelectedCategories = allCategories.map(choice => choice.id);
    }
    if (nextSelectedCategories.length === 1) {
      setSpotlightMode(SpotlightMode.On);
    } else {
      setSpotlightMode(SpotlightMode.Off);
    }
    setSpotlightId(nextSpotlightId);
    setSelected(nextSelectedCategories);
  };

  return {
    onShowHideClick, onIsolateClick, setSpotlightMode, spotlightMode,
  };

};
