import React, { memo } from 'react';
import styled from 'styled-components';

export const Root = styled.div`
  padding: 0.5rem 0.85rem;
  border-radius: 3px;
  color: white;
  font-size: 0.7rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-right: 5px;
  white-space: nowrap;

  @media screen and (max-width: 1400px) {
    white-space: normal;
    padding: 0.5rem 0.55rem;
  }
`;

interface IProps {
  color: string;
  text: string;
}

export default memo((props: IProps) => {
  const {color, text} = props;
  const style = {
    backgroundColor: color,
  };
  return (
    <Root style={style}>
      {text}
    </Root>
  );
});
