import React from 'react';
import DetailOverlay from '../../sharedComponents/DetailOverlay';
import {
  getDataPointTooltipKey,
  ITooltipMapDatum,
  IYearDataPoint,
} from '../../workerStore/stack/Utils';

interface IProps {
  tooltipMap: Record<string, ITooltipMapDatum>;
  detailed: IYearDataPoint;
  hideOverlay: () => void;
  rememberEl: (el: HTMLElement | null) => void;
}
export default class extends React.PureComponent<IProps, {}> {
  render() {
    const {
      hideOverlay, detailed, tooltipMap,
      rememberEl,
    } = this.props;

    if (detailed === undefined) {
      return null;
    } else {
      const {detailOverlayInfo} = detailed;
      const key = getDataPointTooltipKey(detailed);
      const retrieved = tooltipMap[key];
      if (retrieved === undefined) {
        return null;
      } else {
        const {color, longLabel} = retrieved;
        return (
          <DetailOverlay
            rows={detailOverlayInfo}
            color={color}
            rememberEl={rememberEl}
            title={longLabel}
            hideOverlay={hideOverlay}/>
        );
      }
    }

  }
}
